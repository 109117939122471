import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectNavigationBackStack } from '../data/container.slice';
import { useIkon } from './use-ikon';

export function useIkonBackNavigation() {
  const ikon = useIkon();
  const stack = useSelector(selectNavigationBackStack);
  const count = useRef(0);

  // Try to prevent browser back navigation while we have navigation back actions available.
  useEffect(() => {
    const diff = stack.length - count.current;
    if (diff > 0) {
      window.history.pushState({ actionId: stack[0].actionId, containerId: stack[0].containerId }, '', window.location.href);
    } else if (diff < 0) {
      // window.history.back();
    }
    count.current = stack.length;
  }, [stack]);

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (ikon && stack.length) {
        event.preventDefault();
        const { actionId } = stack[0];
        ikon.sendAction(actionId);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack, ikon?.sendAction]);
}
