import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInputOptionsStack } from '../../shared/data/container.slice';

export function useUserInputOptions() {
  const options = useSelector(selectUserInputOptionsStack);

  return useMemo(() => {
    const option = options.length ? options[0].options : undefined;
    return {
      textInputEnabled: option?.textInputEnabled ?? true,
      voiceInputEnabled: option?.voiceInputEnabled ?? true,
    };
  }, [options]);
}
