import { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { HAS_DEBUG_CONTAINER } from '../../shared/constants';
import { selectContainerById } from '../../shared/data/container.slice';
import { RootState } from '../../shared/data/store';
import { UiContainerElements } from './ui-container-elements';

export function UiContainer({
  containerId,
  myMessage,
  disabled,
  stagger,
  isRootContainer,
}: {
  containerId: string;
  myMessage?: boolean;
  disabled?: boolean;
  stagger?: MutableRefObject<number>;
  isRootContainer?: boolean;
}) {
  const container = useSelector((state: RootState) => selectContainerById(state, containerId));

  if (!container) return null;
  if (container.elements.length === 0) return null;

  const isDisabled = container.isHistory || disabled;
  const rendered = <UiContainerElements container={container} myMessage={myMessage} disabled={isDisabled} stagger={stagger} />;

  if (HAS_DEBUG_CONTAINER && isRootContainer) {
    return (
      <div style={{ width: '100%' }}>
        <small style={{ paddingLeft: '12px' }}>
          c: {container.containerId} g: {container.groupId} t: {container.preciseCreatedAt.toString()}
        </small>
        {rendered}
      </div>
    );
  }

  return rendered;
}
