import { Box, Fade } from '@mui/material';
import Stack from '@mui/material/Stack';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { STAGGER_DELAY } from '../../shared/constants';
import { RootContainer } from '../../shared/data/container.slice';
import { selectAuthenticatedUserId } from '../auth/auth.slice';

export function ChatRootContainer({ group, centerMessages }: { centerMessages?: boolean; group: RootContainer }) {
  const userId = useSelector(selectAuthenticatedUserId);
  const staggerRef = useRef<number>(0);

  const myMessage = group.userId === userId;

  return (
    <Stack direction="column" spacing={1} useFlexGap className="ikon-group">
      {group.containers
        .filter((c) => c.visible)
        .map((container) => (
          <ChatRootContainerContent key={container.id} container={container} myMessage={myMessage} centerMessages={centerMessages} stagger={staggerRef} />
        ))}
    </Stack>
  );
}

export function ChatRootContainerContent({
  container,
  myMessage,
  centerMessages,
  stagger,
}: {
  container: RootContainer['containers'][0];
  myMessage: boolean;
  centerMessages?: boolean;
  stagger: MutableRefObject<number>;
}) {
  const isAnimated = stagger && container.hasCards === false;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout;
    if (isAnimated) {
      const currentStagger = stagger.current;
      stagger.current += STAGGER_DELAY;
      timeout = setTimeout(() => setVisible(true), currentStagger);
    } else setVisible(true);

    return () => {
      if (timeout) clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rendered = (
    <Box
      key={container.id}
      className="ikon-group-content"
      sx={{
        display: 'flex',
        justifyContent: myMessage ? 'end' : 'start',
        marginLeft: !centerMessages && myMessage ? { xs: '16px', sm: '40px' } : null,
        marginRight: !centerMessages && !myMessage ? { xs: '16px', sm: '40px' } : null,
      }}
    >
      <UiContainer containerId={container.id} myMessage={myMessage} stagger={stagger} isRootContainer={true} />
    </Box>
  );

  if (isAnimated) {
    return (
      <Fade appear={true} in={visible} timeout={200}>
        {rendered}
      </Fade>
    );
  }

  return rendered;
}
