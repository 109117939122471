import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectChatGroups, selectContainerEntities } from '../../shared/data/container.slice';
import { useAuth } from '../../shared/hooks/use-auth';

export function useContainersUserTopScroll() {
  const { user } = useAuth();
  const containers = useSelector(selectContainerEntities);
  const groups = useSelector(selectChatGroups);

  const [oldGroups, setOldGroups] = useState([]);
  const [newGroups, setNewGroups] = useState([]);

  useEffect(() => {
    let updated = false;
    const group = groups.at(-2);
    if (group && group.containers.length) {
      const container = containers[group.containers[0].id];
      if (container && container.userId === user.id) {
        if (newGroups.length !== 2 || newGroups[1].id !== group.id || newGroups[0].id !== groups.at(-1).id) {
          setOldGroups(groups.slice(0, -2));
          setNewGroups(groups.slice(-2, groups.length));
          updated = true;
        }
      }
    }

    if (!updated) {
      setOldGroups(groups.slice(0, -1));
      setNewGroups(groups.slice(-1, groups.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  return useMemo(() => ({ oldGroups, newGroups }), [oldGroups, newGroups]);
}
