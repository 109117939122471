import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { selectRoom } from '../features/room/room.slice';
import { api } from '../shared/api/api';
import { NotificationProvider } from '../shared/context/notification.provider';
import { selectNavigationBackStack } from '../shared/data/container.slice';
import { useAuth } from '../shared/hooks/use-auth';
import { useIkon } from '../shared/hooks/use-ikon';
import { useRoomStack } from '../shared/hooks/use-room-stack';
import { useRooms } from '../shared/hooks/use-rooms';
import { useSidebar } from '../shared/hooks/use-sidebar';
import { useSpace } from '../shared/hooks/use-space';
import { HomeSmartIcon } from './icons/home-smart.icon';
import { UserMenuDesktop } from './user-menu/user-menu-desktop';
import { UserMenuMobile } from './user-menu/user-menu-mobile';

export function Navigation() {
  const navigate = useNavigate();
  const roomStack = useRoomStack();
  const { isAuthenticated, isAnonymous } = useAuth();
  const space = useSpace();
  const room = useSelector(selectRoom);
  const { sidebarOpen, setSidebarOpen } = useSidebar();
  const { mainRooms } = useRooms();

  const ikon = useIkon();
  const navigationBackStack = useSelector(selectNavigationBackStack);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: roomTemplate } = api.useGetRoomTemplateByIdQuery(room?.roomTemplate, { skip: !room?.roomTemplate });

  function handleToggle(event: { stopPropagation: () => void }) {
    event.stopPropagation();
    setSidebarOpen(!sidebarOpen);
  }

  return (
    <AppBar position="static" elevation={0} sx={{ background: 'none', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ml: 2, mr: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={handleToggle}>
            <MenuIcon />
          </IconButton>

          {mainRooms?.length > 0 && (
            <IconButton
              size="medium"
              onClick={() => navigate(`/rooms/${mainRooms[0].code}`, { state: { interaction: true } })}
              sx={{ fill: 'text.primary', display: { xs: 'none', md: 'inline-flex' } }}
            >
              <HomeSmartIcon color={theme.palette.text.primary} />
            </IconButton>
          )}

          <Box sx={{ alignItems: 'center', gap: 0.5 }}>
            <IconButton
              size="medium"
              disabled={!roomStack.previousRoom && !navigationBackStack.length}
              onClick={() => {
                if (navigationBackStack.length) {
                  const { actionId } = navigationBackStack[0];
                  ikon?.sendAction(actionId);
                  window.history.back();
                  return;
                }

                if (!roomStack.previousRoom) return;

                navigate(`/rooms/${roomStack.previousRoom}`);
                roomStack.goPrevious();
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>

          <Typography variant="subtitle2" component="h1" sx={{ color: 'text.secondary', display: 'inline' }}>
            {roomTemplate?.name || space?.name}
          </Typography>
        </Box>

        {isAuthenticated && !isAnonymous ? (
          <NotificationProvider>{isMobile ? <UserMenuMobile /> : <UserMenuDesktop />}</NotificationProvider>
        ) : (
          <Button component={RouterLink} to="/auth/login" variant="text" sx={{ color: 'text.secondary' }}>
            Log in
          </Button>
        )}

        <Box sx={{ position: 'absolute', left: 0, right: 0, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', zIndex: -1000 }}>
          {/*<Box component="img" src={ai} sx={{ width: '40px', height: '40px' }} />*/}
          {/*<AiLogo />*/}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
