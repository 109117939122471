import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authReducer } from '../../features/auth/auth.slice';
import { chatReducer } from '../../features/chat/chat.slice';
import { debugOverlayReducer } from '../../features/debug-overlay/debug-overlay.slice';
import { overlayReducer } from '../../features/overlay/overlay.slice';
import { currentProfileReducer } from '../../features/profile/current-profile.slice';
import { roomReducer } from '../../features/room/room.slice';
import { secondScreenContainerReducer } from '../../features/second-screen/second-screen-containers.slice';
import { spaceReducer } from '../../features/space/space.slice';
import { api } from '../api/api';
import { authApi } from '../api/auth-api';
import { containerUpdatesReducer } from './container-updates.slice';
import { containerReducer } from './container.slice';
import { embeddedSpaceReducer } from './embedded-space.slice';
import { interactionReducer } from './interaction.slice';
import { roomStackReducer } from './room-stack.slice';
import { sidebarReducer } from './sidebar.slice';

const combinedReducers = combineReducers({
  [api.reducerPath]: api.reducer,
  [authApi.reducerPath]: authApi.reducer,
  auth: authReducer,
  chat: chatReducer,
  container: containerReducer,
  containerUpdates: containerUpdatesReducer,
  currentProfile: currentProfileReducer,
  debugOverlay: debugOverlayReducer,
  embeddedSpace: embeddedSpaceReducer,
  interaction: interactionReducer,
  overlay: overlayReducer,
  room: roomReducer,
  roomStack: roomStackReducer,
  secondScreenContainer: secondScreenContainerReducer,
  sidebar: sidebarReducer,
  space: spaceReducer,
});

export const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, authApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof combinedReducers>;
export type AppDispatch = typeof store.dispatch;
