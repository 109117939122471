import { forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './qsapp.css';

interface QSAppProps {
  id: string;
  loadingAnimation?: ReactNode;
  className?: string;
  onStart?: () => void;
  actions?: Record<string, (action: string) => void>;
  deferLoading?: boolean;
  hideLoadingAnimation?: boolean;
  styles: any;
}

export interface QSRef {
  send: (event: string) => void;
  setLayoutText: (layout: string, element: string, text: string) => void;
}

const EVENT_ORIGIN = 'https://qs.app';
const LOAD_PROGRESS = 'load-progress';
const LOAD_END = 'load-end';
const EVENT_PROGRESS = 100;

const QSApp = forwardRef((props: QSAppProps, ref: Ref<QSRef>) => {
  const [showLoading, setShowLoading] = useState(true);
  const [deferLoading, setDeferLoading] = useState(props.deferLoading || false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!deferLoading && props.deferLoading) {
      setDeferLoading(props.deferLoading);
    }
  }, [props.deferLoading]);

  const receive = (event: MessageEvent) => {
    if (!event.source || event.origin !== EVENT_ORIGIN || event.source !== iframeRef.current?.contentWindow) {
      return;
    }

    switch (event.data.type) {
      case LOAD_PROGRESS:
        if (event.data.progress === EVENT_PROGRESS) {
          setShowLoading(false);
        }
        break;

      case LOAD_END:
        setShowLoading(false);
        break;

      case 'start': {
        if (props.onStart) {
          props.onStart();
        }
        break;
      }

      case 'action': {
        if (props.actions && props.actions[event.data.name]) {
          props.actions[event.data.name](event.data.name);
        }
        break;
      }

      default:
        break;
    }
  };

  const send = (event: string) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage({ type: 'interpreter-send', event }, EVENT_ORIGIN);
    }
  };

  const setLayoutText = (layout: string, element: string, text: string) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage({ type: 'set-text', layout, element, text }, EVENT_ORIGIN);
    }
  };

  useImperativeHandle(ref, () => ({ send, setLayoutText }));

  useEffect(() => {
    window.addEventListener('message', receive, false);

    return () => {
      window.removeEventListener('message', receive);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`qsapp_container ${props.className ? props.className : ''}`} style={props.styles ?? {}}>
      {props.loadingAnimation && !props.hideLoadingAnimation && (showLoading || deferLoading) && (
        <div role="status" className="qsapp_loadingContainer">
          {props.loadingAnimation}
          <span className="sr-only">Loading...</span>
        </div>
      )}

      <iframe src={`https://qs.app/?id=${props.id}`} ref={iframeRef} allow="autoplay" className="qsapp_iframe" />
    </div>
  );
});

export default QSApp;

QSApp.displayName = 'QSApp';
