import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { selectOverlayContainerIds } from '../../shared/data/container.slice';

export function Overlay() {
  const containerRef = useRef<any>(null);
  const [previousId, setPreviousId] = useState<string | null>(null);
  const rootContainerIds = useSelector(selectOverlayContainerIds);

  useEffect(() => {
    if (rootContainerIds.length) {
      if (rootContainerIds.at(0) !== previousId) {
        containerRef.current?.scrollTo(0, 0);
      }
      setPreviousId(rootContainerIds.at(0));
    }
  }, [rootContainerIds, previousId]);

  if (!rootContainerIds?.length) return null;

  return (
    <Box
      ref={containerRef}
      className="ikon-scrollbar"
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(8px)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        zIndex: 1250,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
          height: '100%',
        }}
      >
        <div>
          {rootContainerIds.map((rootContainerId) => (
            <UiContainer key={rootContainerId} containerId={rootContainerId} myMessage={false} />
          ))}
        </div>
      </Box>
    </Box>
  );
}
