import { UIElementLabels } from '@ikon-web/event-shared';
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { MutableRefObject } from 'react';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerColumn({
  container,
  isCard,
  myMessage,
  disabled,
  stagger,
}: {
  container: any;
  isCard: boolean;
  myMessage: boolean;
  disabled?: boolean;
  stagger?: MutableRefObject<number>;
}) {
  const sx: SxProps = {};
  if (container.labels?.includes(UIElementLabels.SizeFullWidth)) {
    sx['width'] = '100%';
  }

  return (
    <Stack
      direction="column"
      spacing={container.gap || 0}
      useFlexGap
      sx={{
        ...sx,
        alignItems: 'left',
        justifyContent: 'left',
      }}
      className="ikon-container-column"
    >
      <UiContainerElements container={container} isCard={isCard} myMessage={myMessage} disabled={disabled} stagger={stagger} />
    </Stack>
  );
}
