import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/700.css';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

import '@fontsource-variable/material-symbols-rounded';

import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { EmbeddedSpaceDetector } from './components/embedded-space-detector';
import { Error } from './components/error';
import { AnalyticsProvider } from './shared/context/analytics.provider';
import { AuthProvider } from './shared/context/auth.provider';
import { IkonProvider } from './shared/context/ikon.provider';
import { ThemeSelectorProvider } from './shared/context/theme-selector.provider';
import { store } from './shared/data/store';

export function App() {
  return (
    <Provider store={store}>
      <EmbeddedSpaceDetector>
        <AuthProvider>
          <AnalyticsProvider>
            <IkonProvider>
              <ThemeSelectorProvider>
                <Sentry.ErrorBoundary fallback={Error}>
                  <Outlet />
                </Sentry.ErrorBoundary>
              </ThemeSelectorProvider>
            </IkonProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </EmbeddedSpaceDetector>
    </Provider>
  );
}
