import { Box } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Disclaimer } from '../../components/disclaimer';
import { selectChatGroups } from '../../shared/data/container.slice';
import { RootState } from '../../shared/data/store';
import { ChatRootContainer } from './chat-root-container';
import { useContainersUserTopScroll } from './use-containers-user-top-scroll';

export function ChatContainersScrollLatestTop(props: { alignEnd: boolean; centerMessages?: boolean; hasFade?: boolean; disableInitialScroll?: boolean }) {
  const groups = useSelector(selectChatGroups);
  const chatContainerUpdates = useSelector((state: RootState) => state.containerUpdates.count);
  const chatContainerForceUpdates = useSelector((state: RootState) => state.containerUpdates.forceCount);
  const { oldGroups, newGroups } = useContainersUserTopScroll();

  const [scrollToBottom, setScrollToBottom] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Handles scrolling from new messages
    // If the user has scrolled up, don't scroll to the bottom
    if (!groups.length) return;

    if (scrollToBottom) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [groups, chatContainerUpdates, scrollToBottom]);

  useEffect(() => {
    // Handles scrolling from force updates, e.g. when message from the current user is received
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [containerRef, chatContainerForceUpdates]);

  const handleContainerScroll = () => {
    if (containerRef.current) {
      // Check if the user has scrolled to the bottom
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
      if (scrollToBottom !== isBottom) {
        setScrollToBottom(scrollTop + clientHeight >= scrollHeight - 10);
      }
    }
  };

  const rootContainers = useMemo(() => {
    return (
      <>
        {oldGroups.map((group) => (
          <ChatRootContainer key={group.id} group={group} centerMessages={props.centerMessages} />
        ))}
      </>
    );
  }, [props.centerMessages, oldGroups]);

  const newRootContainers = useMemo(() => {
    return (
      <>
        {newGroups.map((group) => (
          <ChatRootContainer key={group.id} group={group} centerMessages={props.centerMessages} />
        ))}
      </>
    );
  }, [props.centerMessages, newGroups]);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        maskImage: props.hasFade ? 'linear-gradient(transparent 0%, black 24px, black calc(100% - 24px), transparent 100%)' : null,
        px: 2,
      }}
      onScroll={handleContainerScroll}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          placeContent: props.alignEnd ? 'flex-end' : 'flex-start',
          gap: 2,
          maxWidth: '800px',
          margin: '0 auto',
          pt: props.hasFade ? 3 : 1,
          pb: 0,
        }}
      >
        {rootContainers}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          placeContent: 'flex-start',
          gap: 2,
          maxWidth: '800px',
          margin: '0 auto',
          py: props.hasFade ? 3 : 1,
          minHeight: '100%',
        }}
      >
        {newRootContainers}
        {groups.length > 0 && <Disclaimer />}
      </Box>
    </Box>
  );
}
