import { UIStreamCategories, UIVisibilityType } from '@ikon-web/event-shared';
import { UiContainerModel, UiContainerOrElement, UiElement, UiElementType } from '@ikon-web/ikon-client';
import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const containerAdapter = createEntityAdapter({
  selectId: (message: UiContainerModel) => message.containerId,
});

export type RootContainer = { id: string; userId: string; containers: { id: string; sortingId: number; visible: boolean; hasCards: boolean }[]; timestamp: bigint };

const extraState: {
  chatContainers: RootContainer[];
  chatContainerUpdatesCount: number;
  debugOverlayContainers: RootContainer[];
  headerContainers: RootContainer[];
  secondScreenContainers: RootContainer[];
  overlayContainers: RootContainer[];
  navigationBackStack: { containerId: string; actionId: string }[];
  userInputOptionsStack: { containerId: string; options: { textInputEnabled: boolean; voiceInputEnabled: boolean } }[];
} = {
  chatContainers: [],
  chatContainerUpdatesCount: 0,
  debugOverlayContainers: [],
  headerContainers: [],
  secondScreenContainers: [],
  overlayContainers: [],
  navigationBackStack: [],
  userInputOptionsStack: [],
};

const updateVisibility = (rootContainer: RootContainer, entities: Record<string, UiContainerModel>) => {
  let isGroupStable = true;
  for (const c of rootContainer.containers) {
    const container = entities[c.id];
    if (!container) continue;
    if (!container.isStable) isGroupStable = false;

    c.visible = false;
    switch (container.visibility) {
      case UIVisibilityType.Always:
        c.visible = true;
        break;
      case UIVisibilityType.AfterEarlierStable:
        c.visible = isGroupStable;
        break;
    }
  }
};

const getRootContainer = (state: RootState['container'], container: UiContainerModel) => {
  switch (container.category) {
    case UIStreamCategories.Chat:
      return state.chatContainers.find((c) => c.id === container.groupId);
    case UIStreamCategories.DebugOverlay:
      return state.debugOverlayContainers.find((c) => c.id === container.groupId);
    case UIStreamCategories.Header:
      return state.headerContainers.find((c) => c.id === container.groupId);
    case UIStreamCategories.SecondScreen:
      return state.secondScreenContainers.find((c) => c.id === container.groupId);
    case UIStreamCategories.Overlay:
      return state.overlayContainers.find((c) => c.id === container.groupId);
  }
};

const setRootContainer = (entities: Record<string, UiContainerModel>, groupContainers: RootContainer[], container: UiContainerModel) => {
  const group = groupContainers.find((c) => c.id === container.groupId);
  if (container.isUpdate) {
    if (!group) return;
    updateVisibility(group, entities);
    return;
  }

  if (group) {
    // New container added to existing group
    if (group.containers.some((c) => c.id === container.containerId)) {
      console.warn('[ContainerSlice] Container already exists in group and is not an update', container.containerId);
      return;
    }

    group.containers.push({
      id: container.containerId,
      sortingId: container.sortingId,
      visible: true,
      hasCards: hasCards([container]),
    });
    group.containers.sort((a, b) => a.sortingId - b.sortingId);
    updateVisibility(group, entities);
  } else {
    // New group started
    groupContainers.push({
      id: container.groupId,
      userId: container.userId,
      containers: [
        {
          id: container.containerId,
          sortingId: container.sortingId,
          visible: true,
          hasCards: hasCards([container]),
        },
      ],
      timestamp: container.preciseCreatedAt,
    });
    groupContainers.sort((a, b) => (a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0));
  }
};

const hasCards = (containers: UiContainerOrElement[]): boolean => {
  for (const container of containers) {
    if (container.type === 'card') {
      return true;
    }
    if ('elements' in container && hasCards(container.elements)) {
      return true;
    }
  }
  return false;
};

const removeContainerById = (state: RootState['container'], id: string) => {
  const removeContainer = (groupId: string, containerId: string) => {
    containerAdapter.removeOne(state, containerId);

    let containerIndex: number;
    let groupIndex = state.chatContainers.findIndex((c) => c.id === groupId);
    if (groupIndex >= 0) {
      const rootContainer = state.chatContainers[groupIndex];
      containerIndex = rootContainer.containers.findIndex((c) => c.id === containerId);
      if (containerIndex >= 0) rootContainer.containers.splice(containerIndex, 1);
      if (rootContainer.containers.length === 0) state.chatContainers.splice(groupIndex, 1);
    }

    groupIndex = state.headerContainers.findIndex((c) => c.id === groupId);
    if (groupIndex >= 0) {
      const rootContainer = state.headerContainers[groupIndex];
      containerIndex = rootContainer.containers.findIndex((c) => c.id === containerId);
      if (containerIndex >= 0) rootContainer.containers.splice(containerIndex, 1);
      if (rootContainer.containers.length === 0) state.headerContainers.splice(groupIndex, 1);
    }

    groupIndex = state.debugOverlayContainers.findIndex((c) => c.id === groupId);
    if (groupIndex >= 0) {
      const rootContainer = state.debugOverlayContainers[groupIndex];
      containerIndex = rootContainer.containers.findIndex((c) => c.id === containerId);
      if (containerIndex >= 0) rootContainer.containers.splice(containerIndex, 1);
      if (rootContainer.containers.length === 0) state.debugOverlayContainers.splice(groupIndex, 1);
    }

    groupIndex = state.overlayContainers.findIndex((c) => c.id === groupId);
    if (groupIndex >= 0) {
      const rootContainer = state.overlayContainers[groupIndex];
      containerIndex = rootContainer.containers.findIndex((c) => c.id === containerId);
      if (containerIndex >= 0) rootContainer.containers.splice(containerIndex, 1);
      if (rootContainer.containers.length === 0) state.overlayContainers.splice(groupIndex, 1);
    }

    groupIndex = state.navigationBackStack.findIndex((c) => c.containerId === containerId);
    if (groupIndex >= 0) {
      state.navigationBackStack.splice(groupIndex, 1);
    }

    groupIndex = state.userInputOptionsStack.findIndex((c) => c.containerId === containerId);
    if (groupIndex >= 0) {
      state.userInputOptionsStack.splice(groupIndex, 1);
    }
  };

  const processElements = (elements: UiContainerOrElement[]) => {
    for (const element of elements) {
      if ('elements' in element) processElements(element.elements);
      if (element.type === 'container') removeContainer(element.groupId, element.containerId);
    }
  };

  const container = state.entities[id];
  if (container) {
    removeContainer(container.groupId, container.containerId);
    processElements(container.elements);
  }
};

const containerSlice = createSlice({
  name: 'container',
  initialState: containerAdapter.getInitialState(extraState),
  reducers: {
    receiveContainer: (state, action: PayloadAction<{ container: UiContainerModel; isFromCurrentUser?: boolean }>) => {
      const processElements = (elements: UiContainerOrElement[], currentContainer: UiContainerModel) => {
        for (const element of elements) {
          // Looks for navigation-back elements and adds them to the navigation back stack
          if (element.type === UiElementType.UiElement) {
            const currentElement = element.element as UiElement;
            if (currentElement.name === 'navigation-back') {
              if (currentContainer) {
                const index = state.navigationBackStack.findIndex((c) => c.containerId === currentContainer.containerId);
                if (index >= 0) {
                  state.navigationBackStack[index] = { containerId: currentContainer.containerId, actionId: currentElement.actions['navigation-back'].action };
                } else {
                  state.navigationBackStack.push({ containerId: currentContainer.containerId, actionId: currentElement.actions['navigation-back'].action });
                }
              }
            }

            if (currentElement.name === 'user-input-options') {
              try {
                const options: { TextInputEnabled: boolean; VoiceInputEnabled: boolean } = JSON.parse(currentElement.arguments);
                if (currentContainer) {
                  const index = state.userInputOptionsStack.findIndex((c) => c.containerId === currentContainer.containerId);
                  if (index >= 0) {
                    state.userInputOptionsStack[index] = {
                      containerId: currentContainer.containerId,
                      options: { textInputEnabled: options.TextInputEnabled, voiceInputEnabled: options.VoiceInputEnabled },
                    };
                  } else {
                    state.userInputOptionsStack.push({
                      containerId: currentContainer.containerId,
                      options: { textInputEnabled: options.TextInputEnabled, voiceInputEnabled: options.VoiceInputEnabled },
                    });
                  }
                }
              } catch (err) {
                console.error('[Meet] Failed to parse user-input-options arguments', err);
              }
            }
          }

          if (element.type === 'container') {
            containerAdapter.setOne(state, element);
          }
          if ('elements' in element) processElements(element.elements, element.type === 'container' ? element : currentContainer);
        }
      };

      const { container } = action.payload;

      if (container.type !== 'container') return;

      // Updates where container is no longer in memory can be ignored
      if (container.isUpdate && !state.ids.includes(container.containerId)) {
        console.warn('[ContainerSlice] Received update for container that is not in memory', container.containerId);
        return;
      }

      // Set container entity into store
      containerAdapter.setOne(state, container);
      processElements(container.elements, container);

      // Handle root containers
      switch (container.category) {
        case UIStreamCategories.Chat:
          state.chatContainerUpdatesCount++;
          setRootContainer(state.entities, state.chatContainers, container);
          break;
        case UIStreamCategories.DebugOverlay:
          setRootContainer(state.entities, state.debugOverlayContainers, container);
          break;
        case UIStreamCategories.Header:
          setRootContainer(state.entities, state.headerContainers, container);
          break;
        case UIStreamCategories.SecondScreen:
          setRootContainer(state.entities, state.secondScreenContainers, container);
          break;
        case UIStreamCategories.Overlay:
          setRootContainer(state.entities, state.overlayContainers, container);
          break;
      }
    },
    removeContainer: (state, action: PayloadAction<string>) => {
      removeContainerById(state, action.payload);
    },
    receiveText: (state, action: PayloadAction<{ containerId: string; elementId: number; text: string }>) => {
      const container = state.entities[action.payload.containerId];
      if (!container) return;

      let element: UiContainerOrElement | undefined;
      const findElement = (elements: UiContainerOrElement[]) => {
        for (const e of elements) {
          if ('elements' in e) findElement(e.elements);
          if (e['elementId'] === action.payload.elementId) {
            element = e;
            return;
          }
        }
      };
      findElement(container.elements);

      if (!element) return;
      (element as any).element.text += action.payload.text;
    },
    setContainerStable: (state, action: PayloadAction<string>) => {
      const container = state.entities[action.payload];
      if (!container) return;

      container.isStable = true;
      updateVisibility(getRootContainer(state, container), state.entities);
    },
    clearChatContainers: (state) => {
      const chatContainers = [...state.chatContainers];
      for (const chatContainer of chatContainers) {
        for (const container of chatContainer.containers) {
          removeContainerById(state, container.id);
        }
      }
      state.chatContainers = [];
    },
    reset: () => containerAdapter.getInitialState(extraState),
  },
});

export const containerReducer = containerSlice.reducer;

export const { receiveContainer, removeContainer, receiveText, setContainerStable, clearChatContainers, reset: resetContainer } = containerSlice.actions;

export const { selectById: selectContainerById, selectEntities: selectContainerEntities } = containerAdapter.getSelectors<RootState>((state) => state.container);

export const selectChatGroups = (state: RootState) => state.container.chatContainers;

export const selectChatContainerIds = createSelector(
  (state: RootState) => state.container.chatContainers,
  (chatContainers) => chatContainers.flatMap((c) => c.containers.filter((cc) => cc.visible).map((cc) => cc.id)),
);

export const selectChatContainerUpdatesCount = createSelector(
  (state: RootState) => state.container,
  (containerState) => containerState.chatContainerUpdatesCount,
);

export const selectDebugOverlayContainerIds = createSelector(
  (state: RootState) => state.container.debugOverlayContainers,
  (debugOverlayContainers) => debugOverlayContainers.flatMap((c) => c.containers.map((cc) => cc.id)),
);

export const selectHeaderContainerIds = createSelector(
  (state: RootState) => state.container.headerContainers,
  (headerContainers) => headerContainers.flatMap((c) => c.containers.map((cc) => cc.id)),
);

export const selectSecondScreenContainerIds = createSelector(
  (state: RootState) => state.container.secondScreenContainers,
  (secondScreenContainers) => secondScreenContainers.flatMap((c) => c.containers.map((cc) => cc.id)),
);

export const selectOverlayContainerIds = createSelector(
  (state: RootState) => state.container.overlayContainers,
  (overlayContainers) => overlayContainers.flatMap((c) => c.containers.map((cc) => cc.id)),
);

export const selectNavigationBackStack = (state: RootState) => state.container.navigationBackStack;

export const selectUserInputOptionsStack = (state: RootState) => state.container.userInputOptionsStack;
