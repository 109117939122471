import { UiElementVegaChart } from '@ikon-web/ikon-client';
import { useMemo } from 'react';
import { VegaLite } from 'react-vega';

export function UiVegaChart({ element: { spec, data } }: { element: UiElementVegaChart }) {
  const mutableSpec = useMemo(() => JSON.parse(JSON.stringify(spec)), [spec]);
  const mutableData = useMemo(() => JSON.parse(JSON.stringify(data)), [data]);

  if (!spec || !data) return null;

  return <VegaLite spec={mutableSpec} data={mutableData} />;
}
