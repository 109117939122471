import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

const initialState: { open: boolean } = { open: false };

const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = false;
    },
    reset: () => initialState,
  },
});

export const overlayReducer = overlaySlice.reducer;

export const { open: openOverlay, close: closeOverlay, reset: resetOverlay } = overlaySlice.actions;

export const selectOverlayOpen = (state: RootState) => state.overlay.open;
