import { UiContainerOrElement } from '@ikon-web/ikon-client';
import { MutableRefObject } from 'react';
import { UiElement } from './ui-element';

export function UiContainerElements({
  container,
  isCard = false,
  myMessage,
  disabled,
  stagger,
}: {
  container: UiContainerOrElement;
  isCard?: boolean;
  myMessage: boolean;
  disabled?: boolean;
  stagger?: MutableRefObject<number>;
}) {
  if ('elements' in container) {
    return container.elements.map((element, index) => {
      return <UiElement key={index} element={element} isCard={isCard} myMessage={myMessage} disabled={disabled} stagger={stagger} />;
    });
  }
  return null;
}
