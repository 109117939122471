import { SpaceFlag } from '@ikon-web/space-types';
import { Box } from '@mui/material';
import { useSpace } from '../../shared/hooks/use-space';
import { ChatContainers } from './chat-containers';
import { ChatContainersScrollLatestTop } from './chat-containers-scroll-latest-top';
import { ChatHeaderContainers } from './chat-header-containers';
import { ChatInput } from './chat-input';

export function Chat({
  sendMessage,
  sendFile,
  setMicrophone,
  controlPosition,
  enableInput,
  enablePushToTalk,
  disableInitialScroll,
}: {
  sendMessage: (text: string, isComplete: boolean) => void;
  sendFile: (file: File) => void;
  setMicrophone: (enabled: boolean) => void;
  controlPosition: string;
  enableInput: boolean;
  enablePushToTalk: boolean;
  disableInitialScroll?: boolean;
}) {
  const space = useSpace();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        height: '100%',
        maxWidth: '100%',
      }}
    >
      <ChatHeaderContainers />
      {space.flags?.includes(SpaceFlag.ChatScrollLatestTop) ? (
        <ChatContainersScrollLatestTop alignEnd={enableInput} centerMessages={!enableInput} hasFade={enableInput} disableInitialScroll={disableInitialScroll} />
      ) : (
        <ChatContainers alignEnd={enableInput} centerMessages={!enableInput} hasFade={enableInput} disableInitialScroll={disableInitialScroll} />
      )}

      {enableInput && (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', px: 2, scrollbarGutter: 'stable' }}>
          <ChatInput sendMessage={sendMessage} sendFile={sendFile} setMicrophone={setMicrophone} enablePushToTalk={enablePushToTalk} controlPosition={controlPosition} />
        </Box>
      )}
    </Box>
  );
}
