import { UiElementQS } from '@ikon-web/ikon-client';
import { isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useIkon } from '../../shared/hooks/use-ikon';
import QSApp, { QSRef } from './qsapp/qsapp';

export function UiQSApp({ element }: { element: UiElementQS }) {
  const [isReady, setIsReady] = useState(false);
  const [styles, setStyles] = useState<any>({});
  const ikon = useIkon();

  const eventRef = useRef<string[]>([]);
  const qsRef = useRef<QSRef>(null);

  useEffect(() => {
    if (qsRef.current && isReady) {
      if (element.arguments.events && !isEqual(element.arguments.event, eventRef.current)) {
        eventRef.current = element.arguments.events;
        for (const event of eventRef.current) {
          qsRef.current.send(event);
        }
      }

      if (element.arguments.texts && Array.isArray(element.arguments.texts)) {
        for (const value of element.arguments.texts) {
          try {
            const { layout, element: el, text } = value;
            qsRef.current.setLayoutText(layout, el, text);
          } catch {
            console.warn('[Meet] Invalid qsapp text');
          }
        }
      }
    }

    setStyles(element.arguments.styles || {});
  }, [element, isReady]);

  return <QSApp id={element.name} ref={qsRef} actions={{ click: () => ikon.sendAction(element.actionId) }} onStart={() => setIsReady(true)} hideLoadingAnimation={true} styles={styles} />;
}
